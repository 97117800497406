<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">Training<br /></h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                A wide range of engaging and intuitive training services.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <h1 class="column is-size-4">
        We provide a full range of training services for our web solutions to
        make sure that our clients can take <span>full ownership</span> of their
        systems and <span>enjoy</span> the benefits of their investment.
        <br /><br />
        Our training staff are available to organise and provide
        <span>informative</span>, <span>fun</span> and
        <span>well-structured</span> training workshops and seminars
        <span>anywhere</span> around the country. We also pride ourselves on
        drafting <span>excellent</span> training and end-user documentation
        customised to our client's specific needs which is always
        <span>clearly</span> and <span>concisely</span> written in plain English
        and above all, <span>useful</span> for their day to day system usage.
        <br /><br />
        We specialise in <span>scenario-based training</span> with the capacity
        to deliver <span>Plone CMS</span> alongside related training to up to 50
        attendees at a time either on-site at our client's locations or a
        dedicated training facility. <br /><br />
        We offer a comprehensive range of <span>Plone</span> related training
        services that range from simple content management tasks right through
        to administration and development.
      </h1>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/service-banner/training-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/service-banner/training-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Training"
}
</script>
